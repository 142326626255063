import React from 'react'
import Menu from './Menu'

const Blog = () => (
  <>
    <h1>Blog</h1>
    <Menu />
  </>
)

export default Blog
