import React from 'react'
import Menu from './Menu'

const App = () => (
  <>
    <h1>Accueil</h1>
    <Menu />
  </>
)

export default App
