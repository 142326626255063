import React from 'react'
import Menu from './Menu'

const NotFound = () => (
  <>
    <h1>404 : il n'y a rien ici.</h1>
    <Menu />
  </>
)

export default NotFound
