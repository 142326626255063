import React from 'react'
import Menu from './Menu'

const About = () => (
  <>
    <h1>About</h1>
    <Menu />
  </>
)

export default About
